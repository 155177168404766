<template>
  <section id="t-and-c-referral-program-app" :class="classNoQuery">
    <div class="banner-wrapper-t-and-c-app">
      <ImageLazy
        id="banner-referral-program"
        src="/images/icon_referral-program.svg"
        alt="Lion Parcel Referral Program"
      />
    </div>
    <svg id="svg-referral-program" width="100%" height="100%" viewBox="0 0 360 662" fill="none"
         xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7" filter="url(#filter0_f_854_110769)">
        <path
          d="M474.772 412.093C474.772 571.168 337.598 484.359 168.386 484.359C-0.82634 484.359 -138 571.168 -138 412.093C-138 253.017 -0.82634 154 168.386 154C337.598 154 474.772 253.017 474.772 412.093Z"
          fill="#D4797C"
        />
      </g>
      <defs>
        <filter id="filter0_f_854_110769" x="-291.193" y="0.807022" width="919.158" height="660.386"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="76.59648895263672" result="effect1_foregroundBlur_854_110769"/>
        </filter>
      </defs>
    </svg>
    <section class="main-content-t-n-c">
      <span class="main-content-t-n-c__title">Untung Tanpa Batas</span>
      <section class="section-content" style="margin-top: 32px;">
        <h2>Apa itu Program Untung Tanpa Batas?</h2>
        <p>
          Kamu bisa jadi Jutawan di Lion Parcel dengan ikuti Program Untung Tanpa Batas.
        </p>
        <p>
          Di program ini, kamu bisa mendapatkan passive income tanpa batas hingga jutaan rupiah setiap bulannya dengan
          ajak temanmu daftar & transaksi. Sangat mudah! Makin banyak teman yang bertransaksi, makin untung!
        </p>
        <p>
          Yuk, jadilah Jutawan & raih keuntungan tanpa batas!
        </p>
      </section>
      <div class="break-section"></div>
      <section class="section-content section-content__simulation">
        <section class="simulation-referral">
          <span class="main-content-t-n-c__title" style="top: -23px;">Cara Mendapatkan Bonus</span>
          <div style="display: flex;flex-wrap: wrap">
            <div class="simulation-referral__info">
              <div>
                <span class="dot-circle">1</span>
                <img src="/images/terms-and-conditions/referral-progam-1.svg" alt="referral-icon 1">
              </div>
              <div>
                Bagikan kode referral ke temanmu
              </div>
            </div>
            <div class="simulation-referral__info">
              <div>
                <span class="dot-circle">2</span>
                <img src="/images/terms-and-conditions/referral-progam-2.svg" alt="referral-icon 1">
              </div>
              <div>
                Dapatkan total <b>7% bonus</b>, <b>2%</b> dari transaksi temanmu & <b>5%</b> dari transaksimu
              </div>
            </div>

            <div class="simulation-referral__info">
              <div>
                <span class="dot-circle">3</span>
                <img src="/images/terms-and-conditions/referral-progam-3.svg" alt="referral-icon 1">
              </div>
              <div>
                Makin banyak temanmu & makin banyak bonusmu
              </div>
            </div>

            <div class="simulation-referral__info">
              <div>
                <span class="dot-circle">4</span>
                <img src="/images/terms-and-conditions/referral-progam-4.svg" alt="referral-icon 1">
              </div>
              <div>
                Ajak teman tanpa batas & dapatkan bonus tanpa batas
              </div>
            </div>
          </div>

        </section>
        <List style="margin-top: 14px;" :datas="dataListReferralProgram">
        </List>
      </section>
      <div class="break-section"></div>
      <section class="section-content">
        <h2>Info Lainnya</h2>
        <div class="section-content__tabbing">
          <ButtonTab
            v-for="item in otherInfoReferralProgram"
            :id="item.id"
            :key="item.id + item.isActive"
            :text="item.tab"
            :is-active="item.isActive"
            @click="(e) => onClickTable(e, item.id)"
          />
        </div>
        <div v-if="dataTabs.length > 0" class="section-content__result-tabbing">
          <FAQ
            wrapper-class="wrapper-border"
            :data="dataTabs[0].data"
            :type="dataTabs[0].type"
            :slot-name="dataTabs[0].slotName"
          >
            <template>
              <div>
                Lion Parcel dapat menolak bonus jika ada tindakan curang
              </div>
            </template>
          </FAQ>
        </div>
      </section>
      <div class="break-section"></div>
      <section class="section-content section-content__last">
        <ExpandCard
          :id="'faq-referral-program'"
          :use-description="false"
          :url="'lionparcel://app/faqcategory?category_id=42'"
          :title="'Masih Bingung? Yuk! Cek FAQ '"
          icon-url="/images/membership-sk/question.svg"
        />
      </section>
    </section>
  </section>
</template>
<script>
import ImageLazy from "@/components/ImageLazy/index.vue";
import {setImageDefault} from "@/components/ImageLazy/util";
import {
  dataListReferralProgram,
  otherInfoReferralProgram,
  faqContentReferralProgram,
} from "../../app/document";
import List from "@/pages/sk/membership/component/List.vue";
import ButtonTab from "@/pages/sk/membership/component/ButtonTab.vue";
import FAQ from "@/pages/sk/membership/component/FAQ.vue";
import ExpandCard from "@/pages/sk/components/ExpandCard/ExpandCard.vue";

export default {
  name: "TermsAndCondsReferralProgram",
  components: {ExpandCard, FAQ, ButtonTab, List, ImageLazy},
  data() {
    return {
      dataListReferralProgram,
      otherInfoReferralProgram,
      faqContentReferralProgram,
      dataTabs: [],
      iosPlatforms: ["iPhone", "iPad", "iPod"],
      listHideIdentifier: [
        "#main-nav",
        ".new-main-footer",
        ".end-footer",
        "#wa-cs",
      ],
      classNoQuery: "",
    };
  },
  mounted() {
    // if link has no ?default= query, will hide uniqueID on listHideIdentifier
    if (
      !this.$route.query.default &&
      this.isMobile() &&
      window.innerWidth < 991
    ) {
      this.classNoQuery = "no-query-default";
      for (const uniqName of this.listHideIdentifier) {
        const el = document.querySelector(uniqName);
        if (el) {
          el.style.display = "none";
        }
      }
    }
  },
  created() {
    this.dataTabs = this.faqContentReferralProgram[0].content;
  },
  methods: {
    setImageDefault,
    onClickTable(event, id) {
      event.target.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
      this.faqContentReferralProgram.forEach((item, index) => {
        if (item.id === id) {
          this.otherInfoReferralProgram[index].handler(true);
          this.dataTabs = item.content;
        } else {
          this.otherInfoReferralProgram[index].handler(false);
        }
      });
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/style.scss';

$default-padding: 0 12px 0 12px;
$default-padding-desktop: 96px;

#t-and-c-referral-program-app {
  width: 100%;
  height: 100%;
  background: $color-base;
  position: relative;
  padding: 0 1rem;
  padding-bottom: 27px !important;

  @include bigScreen() {
    margin-top: 1rem;
    padding-right: $default-padding-desktop;
    padding-left: $default-padding-desktop;
    padding-top: 2rem;
  }

  @include smallScreen() {
    margin-top: 1rem;
    padding-top: 2rem;
  }

  #svg-referral-program {
    position: absolute;
    bottom: 189px;
    right: 0;
    z-index: 0;

    @include for-size(mobile) {
      bottom: 14%;
      transform: translateY(-13%);
    }
  }

  .banner-wrapper-t-and-c-app {
    display: flex;
    justify-content: center;
    z-index: 1;
    position: relative;
  }

  .main-content-t-n-c {
    z-index: 1;
    height: 100%;
    position: relative;
    border-radius: 12px;
    border: 3px solid #d37a7d;
    background: $color-white;
    margin: 0 auto;

    &__title {
      border-radius: 12px;
      border: 3px solid rgba(255, 255, 255, 0.19);
      background: $color-base;
      color: $color-white;
      position: relative;
      display: flex;
      width: fit-content;
      padding: 10px;
      justify-content: center;
      align-items: center;
      margin: -1.5rem auto;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.32px;
    }
  }

  .section-content {
    color: $color-base-text;
    padding: $default-padding;

    @include bigScreen() {
      padding: 0 24px 0 24px;
    }

    h2 {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.28px;
      margin-bottom: 12px;

      @include bigScreen() {
        font-size: 16px;
        letter-spacing: -0.32px;
      }
    }

    h1 {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.4px;
    }

    p {
      margin-top: .5rem;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.24px;

      @include bigScreen() {
        font-size: 14px;
        letter-spacing: -0.28px;
      }
    }

    &__simulation {
      //margin: 0 auto;
    }

    &__last {
      margin-bottom: 9px;
    }
  }

  .section-content__tabbing {
    padding: initial;
    margin-bottom: 16px;
    display: -webkit-box;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: auto;
    overflow: auto;
    flex-wrap: nowrap;

    @include bigScreen() {
      margin: 16px 0;
      padding: 0;
    }

    > div {
      margin-right: 12px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .break-section {
    margin: 16px 0;
    height: 8px;
    background: $color-gray-pensive;
  }

  ::v-deep #t-n-c-table-referral-program {
    margin-top: 18px;

    .thead .th {
      background: $color-base;
    }

    .tbody .tr .td {
      background: $color-gray-pensive;
      display: flex !important;
      justify-content: center !important;
    }

    .tbody .tr .has-img {
      text-align: center;
      width: 100%;

      div {
        width: 1.5rem;

        @include for-size(mobile) {
          width: 1rem;
        }
      }

      span {
        width: 4rem;
        margin-left: .34rem;
        text-align: start;

        @include for-size(mobile) {
          width: 2rem;
        }
      }
    }

    .section-content__tabbing .button-tab {
      padding: 0 !important;
    }
  }
}

.no-query-default {
  margin-top: -2.5rem !important;

  > .banner-wrapper-t-and-c-app {
    padding-top: 5rem;
  }

  > #svg-referral-program {
    @include for-size(mobile) {
      bottom: 11% !important;
      transform: translateY(-11%) !important;
    }
  }
}

.simulation-referral {
  padding-right: 12px;
  padding-left: 12px;
  padding-bottom: 2rem;
  margin-top: 3.5rem;
  border-radius: 8px;
  background: $color-white;
  box-shadow: 0 1px 4px 0 rgba(26, 20, 33, 0.15);

  @include bigScreen() {
    width: 42vw;
    margin-right: auto;
    margin-left: auto;
  }

  &__info {
    margin-top: .8rem;
    display: flex;
    width: 50%;
    flex-direction: column;

    div:first-child {
      display: flex;
      flex-direction: row;;
    }

    div:last-child {
      font-weight: 400;
      font-size: 14px;
      letter-spacing: -0.21px;

      @include for-size(mobile) {
        font-size: 10px;
        line-height: normal;
        letter-spacing: -0.18px;
      }

      b {
        color: $color-base;
      }
    }

    div img {
      width: 6vw;
      margin: 0 auto;

      @include for-size(mobile) {
        width: 15vw;
      }
    }
  }

  &__info:nth-child(odd) {
    padding-right: 12px;
  }
}

.dot-circle {
  color: $color-white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: $color-base;
  min-height: 1.8rem;
  min-width: 1.8rem;
  max-height: 1.8rem;
  max-width: 1.8rem;
  border-radius: 50%;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;

  @include for-size(mobile) {
    min-height: 1.2rem;
    min-width: 1.2rem;
    max-height: 1.2rem;
    max-width: 1.2rem;
    font-size: 16px;
  }
}
</style>
