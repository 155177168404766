<template>
  <terms-and-conds-referral-program v-if="getRouteName === 'referral-program'"/>
  <page-not-found v-else/>
</template>
<script>
import PageNotFound from "@/pages/not-found/NotFound.vue";
import TermsAndCondsReferralProgram from "./terms-conds-referral-program/TermsAndCondsReferralProgram.vue";

export default {
  name: "TermsAndConditions",
  components: {PageNotFound, TermsAndCondsReferralProgram},
  computed: {
    getRouteName() {
      const getTermsID = this.$route.params.term_and_cond_id || "";
      return getTermsID.toLowerCase()
    },
  },
  beforeMount() {
    if (this.getRouteName === "loyalty-program") {
      this.$router.replace({ path: "/app/t&c", query: this.$route.query })
    }
  }
}
</script>
