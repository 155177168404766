export const headerReferralProgram = [
  "TEMAN YANG DIUNDANG",
  "BONUS PENGUNDANG",
  "BONUS TEMAN YANG DIUNDANG",
];

export const dataItemsReferralProgram = [
  [
    {
      value: "Teman ke-1",
    },
    {
      img: "/images/ics_f_wallet.svg",
      value: "100RB",
    },
    {
      img: "/images/ics_f_parcel_poin.svg",
      value: "250",
    },
  ],
  [
    {
      value: "Teman ke-10",
    },
    {
      img: "/images/ics_f_wallet.svg",
      value: "1 JT",
    },
    {
      img: "/images/ics_f_parcel_poin.svg",
      value: "250",
    },
  ],
  [
    {
      value: "Teman ke-25",
    },
    {
      img: "/images/ics_f_wallet.svg",
      value: "2,5 JT",
    },
    {
      img: "/images/ics_f_parcel_poin.svg",
      value: "250",
    },
  ],
];

export const dataListReferralProgram = [
  {
    value: "Bonus diperhitungkan setiap bulan",
  },
];

export const otherInfoReferralProgram = [
  {
    id: 'TermsForInviteeInviter',
    tab: 'Syarat untuk Kamu/Pengundang',
    isActive: true,
    handler(value) {
      this.isActive = value
    }
  },
  {
    id: 'TermsForInviterInvited',
    tab: 'Syarat untuk Temanmu/Diundang',
    isActive: false,
    handler(value) {
      this.isActive = value
    }
  },
  {
    id: 'TermsDisbursedBonusAndPoint',
    tab: 'Syarat Pencairan Bonus Saldo & Parcel Poin',
    isActive: false,
    handler(value) {
      this.isActive = value
    }
  },
  {
    id: 'OtherTermsReferral',
    tab: 'Syarat Lainnya',
    isActive: false,
    handler(value) {
      this.isActive = value
    }
  },
]

export const faqContentReferralProgram = [
  {
    id: otherInfoReferralProgram[0].id,
    content: [
      {
        title: 'Syarat untuk Kamu/Pengundang',
        data: [
          {
            value: "Bagikan kode unik referralmu dan undang temanmu yang belum memiliki akun Lion Parcel dan menggunakan perangkat (handphone) yang berbeda",
          },
          {
            value: "Kamu berhak mendapatkan 2% bonus saldo dari setiap transaksi teman yang diundang.",
          },
          {
            value: "Bonus saldo bisa dipakai untuk Pick Up, Drop Paket, dan dapat dicairkan ke Bank dengan minimal saldo Rp10.000 (Biaya Admin ditanggung pengguna).",
          },
          {
            value: "Bonus saldo tidak berlaku jika akunmu dihapus."
          }
        ],
        type: "list",
      },
    ]
  },
  {
    id: otherInfoReferralProgram[1].id,
    content: [
      {
        title: 'Syarat untuk Temanmu/Diundang',
        data: [
          {
            value: "Temanmu yang diundang harus memasukkan kode referralmu pada saat pendaftaran di Aplikasi Lion Parcel versi terbaru.",
          },
          {
            value: "Temanmu yang diundang berhak mendapatkan 5% bonus Parcel Poin dari setiap transaksi dan bisa digunakan kembali untuk transaksi.",
          },
          {
            value: "Bonus Parcel Poin bisa dipakai untuk Pick Up, Drop Paket, dan Tukar Hadiah Voucher menarik jutaan rupiah.",
          },
          {
            value: "Bonus Parcel Poin berlaku hingga akhir tahun dan tidak bisa diuangkan"
          },
          {
            value: "Bonus Parcel Poin tidak berlaku jika temanmu menghapus akun, masa berlaku habis hingga akhir tahun, dan tidak bisa diuangkan"
          }
        ],
        type: "list",
      },
    ]
  },
  {
    id: otherInfoReferralProgram[2].id,
    content: [
      {
        title: 'Syarat untuk Temanmu/Diundang',
        data: [
          {
            value: "Bonus dicairkan setiap tanggal 5 di bulan berikutnya tanpa batasan pencairan.",
          },
          {
            value: "Bonus hanya dicairkan setelah transaksi selesai (Paket diterima), tidak berlaku untuk pengiriman retur ke pengirim / Kantor Pusat Lion Parcel.",
          },
          {
            value: "Jumlah bonus dihitung dari setiap transaksi pengguna Aplikasi Lion Parcel (Tidak termasuk harga barang COD, pembayaran Parcel Poin atau Voucher).",
          },
        ],
        type: "list",
      },
    ]
  },
  {
    id: otherInfoReferralProgram[3].id,
    content: [
      {
        title: 'Syarat untuk Temanmu/Diundang',
        data: [
          {
            value: "Periode program berlangsung: 14 Februari 2024 - 31 Desember 2024",
          },
          {
            value: "Jika ada perbedaan dalam catatan atau perhitungan bonus antara Lion Parcel dan Kamu (Pengguna), maka yang berlaku adalah yang diakui oleh Lion Parcel.",
          },
          {
            value: "Lion Parcel melarang segala bentuk tindakan kecurangan seperti:",
            child: [
              "Mengalihkan atau memindahtangankan kepemilikan bonus kepada pihak lainnya.",
              "Mencari keuntungan pribadi melanggar aturan atau merugikan kami atau pihak lain.",
              "Mendapatkan atau memperoleh bonus dengan cara-cara curang.",
            ],
          },
          {
            value: "Dengan mengikuti promo ini, kamu (Pengguna) telah membaca, mengetahui, memahami, dan menyetujui seluruh Syarat & Ketentuan yang berlaku",
          },
        ],
        type: "list",
        slotName: 'child-2'
      },
    ]
  },
]
